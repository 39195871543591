/******* screen-small.less *******/
/*
brakpoints chosen after font-sizes, areas change at different breakpoints
*/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2017-01-10 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.unit > .body,
.unit > form > .body {
  margin: 0.4em 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #c5c4c4;
  color: #c5c4c4;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 0;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #797777 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
sup,
sub {
  vertical-align: baseline;
  position: relative;
  top: -0.6em;
  font-size: 0.7em;
}
sub {
  top: 0.6em;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #979797;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 6px;
}
.vert td,
.vert th {
  padding: 6px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: bold;
  background: #979797;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: #b1b1b1;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #797777;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #797777;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: bold;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #797777;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #baa678;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #f8f7f7;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #825F9B;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #825F9B;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 1800px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #979797;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 6px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #979797;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 6px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 6px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #979797;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 6px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #979797;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 6px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 6px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: #b1b1b1;
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #979797;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 6px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: bold;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 6px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #979797;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 6px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible {
  display: none;
}
/*.cb-mobile .body-mobile {
	display: block;
}*/
/******* site.less 2017-10-13 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(130, 95, 155, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 16px;
  font-size: 1.6rem;
  width: 100%;
  background-color: transparent;
  color: #797777;
  font-family: 'Open Sans', helvetica, sans-serif;
  line-height: 1.375;
  font-weight: 300;
  padding-top: 118px;
}
@media (min-width: 1800px) {
  body {
    padding-top: 125px;
  }
}
@media (max-width: 1023px) {
  body {
    padding-top: 109px;
  }
}
@media (max-width: 375px) {
  body {
    padding-top: 102px;
  }
}
#home {
  float: left;
  max-width: 370px;
  height: 29px;
}
@media (max-width: 375px) {
  #home {
    height: 19px;
  }
}
.logo {
  width: 100%;
  height: auto;
}
.header {
  float: left;
  width: 100%;
  box-sizing: border-box;
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0;
}
.container--head {
  float: left;
  width: 100%;
  background: #f0f0f2;
  padding-bottom: 53px;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  position: relative;
  z-index: 2000;
}
@media (max-width: 1023px) {
  .container--head {
    padding-left: 6.5625%;
    padding-right: 6.5625%;
  }
}
.container--nav {
  float: left;
  width: 100%;
  background-color: #fff;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
.cb-expo {
  float: left;
  width: 100%;
  position: relative;
  *zoom: 1;
}
.cb-expo:before,
.cb-expo:after {
  display: table;
  content: '';
}
.cb-expo:after {
  clear: both;
}
.mainwidth {
  float: left;
  width: 100%;
}
#slides,
.slide,
.slide .cb-landscape {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: none !important;
  max-width: none !important;
  height: 100% !important;
  width: 100% !important;
}
.slide .cb-landscape {
  object-fit: cover;
  object-position: 50% 50%;
}
.footcontent {
  float: left;
  width: 100%;
}
.vcard {
  float: left;
  width: 100%;
}
@media (max-width: 1023px) {
  .vcard {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
#legal {
  float: left;
  margin-top: 40px;
}
#legal .meta {
  float: left;
  margin-right: 20px;
  font-size: 13px;
  line-height: 1.23076923;
  color: #797777;
}
#legal .meta:last-child {
  margin-right: 0;
}
#legal .meta:hover {
  color: #825F9B;
}
.maincontent {
  display: block;
}
.aside {
  float: left;
  width: 100%;
}
.footer {
  /* Rectangle: */
  background-image: radial-gradient(50% 112%, rgba(209, 209, 209, 0.7) 2%, rgba(216, 216, 216, 0.37) 100%);
}
.seam.slim .link > .open,
.tel,
.vcardemail,
.cb-layout3 .link > .open,
.cb-layout2 .link.tiny > .open {
  /*
  footer links & seam.slim team links
  */
  color: #000;
  text-align: center;
  text-transform: uppercase;
  padding: 1.3em 0.5em 1.2em;
  display: inline-block;
  border: 1px solid #979797;
  letter-spacing: 1pt;
}
.tel,
.vcardemail {
  width: 18em;
}
.seam.slim .link > .open:hover,
.seam.slim .link > .open:focus,
.vcardemail:hover,
.vcardemail:focus,
.tel:hover,
.tel:focus,
.cb-layout3 .link > .open:hover,
.cb-layout3 .link > .open:focus,
.cb-layout2 .link.tiny > .open:hover,
.cb-layout2 .link.tiny > .open:focus {
  background: #825F9B;
  color: #fff;
  border-color: #674c7b;
}
.seam.slim .link > .open:active,
.vcardemail:active,
.tel:active,
.cb-layout3 .link > .open:active,
.cb-layout2 .link.tiny > .open:active {
  background: #7a5992;
  box-shadow: 0 0 1px #42304f inset;
}
.cb-layout2 .link.tiny {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
}
.fn {
  display: block;
  font-weight: 300;
}
.fn b {
  font-weight: 600;
}
#cmsbox {
  font-size: 11px;
  font-size: 1.1rem;
  color: #c2c0c0;
  text-transform: lowercase;
  text-align: right;
  position: relative;
  bottom: -25px;
  float: right;
}
#cmsbox > .meta {
  color: inherit;
}
#cmsbox > .meta:hover,
#cmsbox > .meta:focus {
  color: #a9a6a6;
}
.cbdModule--appointmentCta {
  width: auto;
  position: fixed;
  z-index: 1000;
}
.appointmentCta {
  background-color: #825F9B;
  color: #fff !important;
  font-weight: bold;
  text-align: center;
  border-radius: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: rotate(16deg);
  transition: all 0.4s;
}
.appointmentCta:hover,
.appointmentCta:focus {
  color: #fff !important;
  transform: rotate(0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}
.navigation {
  float: left;
  width: 100%;
}
.mobile-navigation {
  float: left;
  width: 100%;
}
.navi,
.navi > .item {
  float: left;
}
/*.navi > .item {
  position: relative;
}*/
.menu {
  letter-spacing: 1pt;
  color: #000 !important;
  display: block;
  text-transform: uppercase;
  position: relative;
}
.menu:after {
  content: '';
  position: absolute;
  bottom: 0;
  user-select: none;
  /*background: #666;*/
}
.path:after {
  background: #8d7293;
}
h1,
.unit h2 {
  color: #000;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
#head {
  width: 100%;
  text-align: center;
}
strong {
  font-weight: bold;
}
/*.text {
  .hyphens(auto);
}
.open,
.load {
  .hyphens(none);
}*/
.loud {
  color: #825F9B;
  letter-spacing: -0.02em;
  hyphens: none;
}
.gist,
.dent,
.list--bullet li {
  list-style: disc;
}
.list--bullet li {
  margin-left: 20px;
}
.list--bullet li > .norm {
  display: inline;
}
a {
  color: #825F9B;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #baa678;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.2em;
}
/*.load {
  background-position: 0 .3em;
}*/
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
#edit .side {
  padding-top: 10px;
  border-top: 2px dashed #ccc;
}
.side:empty {
  margin: 0 !important;
}
.pure:after,
.slim:after {
  height: 2px;
  display: block;
  background: #9b9b9b;
  margin: 2.5em auto 0;
  user-select: none;
}
.main > .pure:after,
.main > .slim:after {
  content: '';
}
.slim:after {
  margin-top: 1.1em;
}
.slim {
  text-align: center;
}
.slim .show .cb-landscape,
.slim .show .cb-portrait {
  margin: 0 auto;
}
.seam.wide:first-child {
  text-align: center;
  border-bottom: 1px solid #9b9b9b;
}
.seam.wide:first-child {
  padding-bottom: 29px;
}
.seam.slim .show.tiny .cb-landscape,
.seam.slim .show.tiny .cb-portrait {
  /* Rectangle: */
}
.seam.slim .link,
.cb-layout3 .link,
.cb-layout2 .link.tiny {
  background: none;
}
.side.side .part,
.side.side .unit,
.side.side .body {
  margin: 0;
  width: 100%;
}
.side .show img {
  max-width: none !important;
  max-height: none !important;
}
.base h2 {
  text-align: center;
}
.cb-form-send.cb-form-send {
  display: block;
  margin: 0 auto;
  font-size: 14px;
  font-size: 1.4rem;
  text-transform: uppercase;
}
.cb-layout1 .part > p {
  max-width: 34em;
  margin: 0 auto;
}
.cb-layout1 .main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cb-layout1 .slim {
  margin-top: 30px;
  margin-bottom: 40px;
}
.cb-layout1 .slim .show + .text,
.cb-layout1 .slim .show + .text + .text {
  margin-top: 0;
}
.cb-layout1 .slim h2 > a {
  color: #797777;
}
.cb-layout1 .slim h2 > a:hover,
.cb-layout1 .slim h2 > a:focus {
  color: #baa678;
}
.cb-layout1 .slim .part > p {
  max-width: 40rem;
  margin: 0 auto;
}
.cb-layout1 .slim .loud {
  letter-spacing: normal;
}
.cb-layout3 .slim {
  text-align: left;
}
.cb-layout3 .slim:after {
  display: none;
}
.cb-layout3 .link > .open,
.cb-layout2 .link.tiny > .open {
  width: 17.2em;
  color: #000 !important;
}
.cb-layout3 .link > .open:hover,
.cb-layout3 .link > .open:focus,
.cb-layout2 .link.tiny > .open:hover,
.cb-layout2 .link.tiny > .open:focus {
  color: #fff !important;
}
.cb-layout3 .unit + .form {
  margin-top: -10px;
}
div.form .cb-form-required {
  display: none;
}
.grow {
  text-transform: none !important;
}
.text-section {
  float: left;
  width: 100%;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #825F9B;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #825F9B;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
/******* forms.less 2016-01-05 *******/
/* Changelog

2016-01-05
added Calendar (partial copy of manage.css)

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 2.2em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #262626;
  background-color: #fff;
  font-weight: normal;
  font-family: 'Open Sans', helvetica, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 2.2em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 16px;
  font-size: 1.6rem;
  color: #444;
  font-weight: normal;
  font-family: 'Open Sans', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #737373;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 16px;
  font-size: 1.6rem;
  display: inline-block;
  padding: 0.1em 1em;
  min-height: 2.2em;
  border: 1px solid;
  border-color: #75558b #5a426c #5a426c #75558b;
  border-radius: 2px;
  background-color: #825F9B;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  line-height: 2.2em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #75558b #5a426c #5a426c #75558b;
  background-color: #8a67a2;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #75558b #5a426c #5a426c #75558b;
  background-color: #674c7b;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.calendar {
  line-height: 1.5;
  position: absolute;
  z-index: 100;
  text-align: right;
  border: 3px solid #666;
  background: #999;
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  font-weight: 700;
  text-align: center;
  color: #eee;
}
.calendar tbody {
  color: #999;
  background-color: #eee;
}
td.show {
  font-weight: 700;
  color: #fff;
  background: #7be;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px;
  text-decoration: none;
  color: inherit;
}
.calendar th > a:hover,
.calendar td > a:hover,
.calendar th > a:focus,
.calendar td > a:focus {
  color: #fff;
  background: #ccc;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 2.2em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
/*!
* Hamburgers
* @description Tasty CSS-animated hamburgers
* @author Jonathan Suh @jonsuh
* @site https://jonsuh.com/hamburgers
* @link https://github.com/jonsuh/hamburgers
*/
.togglenavigation {
  display: inline-block;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
  position: relative;
}
.togglenavigation__outer {
  width: 40px;
  height: 22px;
  display: inline-block;
  position: absolute;
  right: -10px;
  top: 14px;
}
.togglenavigation__inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}
.togglenavigation__inner,
.togglenavigation__inner::before,
.togglenavigation__inner::after {
  width: 29px;
  height: 3px;
  background-color: #825F9B;
  position: absolute;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-duration: 0.14s;
  transition-timing-function: ease;
}
.togglenavigation__inner::before,
.togglenavigation__inner::after {
  content: "";
  display: block;
}
.togglenavigation__inner::before {
  top: -9px;
}
.togglenavigation__inner::after {
  bottom: -9px;
}
/*
 * Squeeze
 */
.togglenavigation--squeeze .togglenavigation__inner {
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.togglenavigation--squeeze .togglenavigation__inner::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease;
}
.togglenavigation--squeeze .togglenavigation__inner::after {
  transition: bottom 0.1s 0.14s ease, -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
/*.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner,
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner::before,
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner::after {
  background: @s_middlegray;
}*/
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease, opacity 0.1s 0.14s ease;
}
.togglenavigation--squeeze.cb-toggle-active .togglenavigation__inner::after {
  bottom: 0;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
}
/******* layout-small.less 2013-1-16 *******/
#home {
  width: 70.50359712%;
  margin-left: -2px;
  margin-top: 27px;
}
.desk {
  width: 86.875%;
}
.togglenavigation {
  margin-top: 9px;
  border-radius: 0;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  width: 40px;
  height: 40px;
  float: right;
  display: inline-block;
  vertical-align: top;
}
.cb-expo {
  height: 511px;
}
#head {
  margin-top: 47px;
}
.side {
  margin-top: 65px;
  margin-bottom: 130px;
}
.footer {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.42857143;
  padding: 34px 0;
}
.seam.slim .link > .open,
.tel,
.vcardemail {
  font-size: 12px;
  font-size: 1.2rem;
}
.vcardemail {
  margin-top: 17px;
}
.fn {
  margin-top: 39px;
}
.cbdModule--appointmentCta {
  right: 6.5625%;
  top: 125px;
}
.appointmentCta {
  width: 100px;
  height: 100px;
  font-size: 15px;
  line-height: 1.06666667;
}
.navigation {
  display: none;
}
@media (max-width: 1799px) {
  html.cb-toggle-target-active .navigation {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 201px;
    display: block;
    background: #f8f8f8;
    z-index: 1999;
  }
  html.cb-toggle-target-active .navigation .desk {
    width: 100%;
    margin-top: 101px;
  }
  html.cb-toggle-target-active .menu {
    border: solid transparent;
    border-width: 11px 5px 11px 20px;
  }
}
@media (max-width: 1023px) {
  div.sub1 > .item {
    width: 100%;
  }
}
.menu {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.5;
}
.menu:after {
  left: 0;
  right: 0;
  height: 3px;
}
a.path {
  display: inline-block;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
.main > .wide:first-child {
  margin-top: 5px;
}
.cb-layout2 .main > .unit:last-child {
  margin-bottom: 50px;
}
.cb-layout3 .base {
  margin-top: 40px;
}
.cb-layout3 .base:empty {
  margin-top: 0;
}
.pure:after,
.slim:after {
  width: 31px;
}
.loud {
  font-size: 22px;
  font-size: 2.2rem;
  line-height: 1.18181818;
}
@media (max-width: 1799px) {
  .cb-layout1 .slim .show img {
    max-width: 65px !important;
  }
}
.cb-layout1 .slim:last-child {
  margin-bottom: 65px;
}
.seam.slim {
  margin-top: 30px;
  margin-bottom: 48px;
}
.seam.slim .show.tiny .cb-landscape,
.seam.slim .show.tiny .cb-portrait {
  width: 75%;
}
.seam.slim .show.tiny + .text .loud {
  margin-top: 0.5em;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
h1,
.unit h2 {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */